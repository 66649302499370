<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品分类管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row>
      <el-col :span="7">
        <el-card style="height: 750px">
          <div class="text">
            <el-select 
              v-model="firstSelected" 
              placeholder="请选择一级类别" 
              @change="changeType"
              @input="getList"
              style="width: 100%;"
            >
              <el-option 
                v-for="oneItem in OneLabelIds"
                :key="oneItem.typeId"
                :label="oneItem.typeName"
                :value="oneItem.typeId"
                value-key="typeId"
              >
              <span style="float: left">{{ oneItem.typeName }}</span>
              <!-- <span class="change-btn"  @click.stop="onClickFirstLevel(oneItem)" style="float: right; visibility: hidden; font-size: 13px">修改</span> -->
            </el-option>
            </el-select>
            <p>
              <el-input
                placeholder="请输入二级关键字过滤类名"
                clearable
                v-model.trim="queryinfoType"
              >
              </el-input>
            </p>
            <p style="text-align: center">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                @click="addDialogVisible = true"
                :disabled="OneLabelquery ? false : true"
                >添加</el-button
              >
              <el-button type="danger" icon="el-icon-remove" @click="deleteType"
                >移除</el-button
              >
            </p>
            <p>
              <el-button class="link1" type="info" disabled>层级树</el-button>
            </p>

            <p>
              <el-tree
                class="elTree"
                :data="TypeData"
                :props="defaultProps"
                @node-click="handleNodeClick"
                :filter-node-method="filterNode"
                ref="tree"
                accordion
                highlight-current
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span v-if="node.level == 1">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="Edited1(data)"
                      >编辑
                    </el-link>
                    <el-link
                      type="danger"
                      :underline="false"
                      style="margin-left: 10px"
                      @click="Delete1(data)"
                      >删除
                    </el-link>
                  </span>
                </span>
              </el-tree>
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="17">
        <el-card style="height: 750px">
          <el-row :gutter="30" class="row2">
            <el-form
              :model="queryinfo"
              ref="queryinfoRef"
              :inline="true"
              style="text-align: left; margin-left: 80px"
            >
              <el-form-item prop="name" style="margin-right: 40px">
                <div style="display: inline-block" class="divSpan">
                  子类别名称：
                </div>
                <div style="display: inline-block">
                  <el-input
                    placeholder="请输入搜索的名称"
                    v-model.trim="queryinfo.name"
                    clearable
                    :disabled="Disabled"
                    @clear="getSerchList"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      :disabled="Disabled"
                      @click="seachChange"
                    >
                    </el-button>
                  </el-input>
                </div>
              </el-form-item>

              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                @click="InsertChildren"
                >新增</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-remove"
                @click="deleteType1"
                >移除</el-button
              >
            </el-form>
          </el-row>
          <el-divider></el-divider>
          <el-row :gutter="30" style="margin-left: 2px; display: flex">
            <el-table
              :data="TypeList"
              :header-cell-style="{
                textAlign: 'center',
                background: '#FAFAFA',
                color: 'rgba(0, 0, 0, 0.85)',
                fontWeight: '500',
              }"
              :cell-style="{
                textAlign: 'center',
              }"
              tooltip-effect="dark"
            >
              <el-table-column type="index" min-width="50px"></el-table-column>
              <el-table-column
                prop="typeName"
                label="分类名称"
                min-width="150px"
              >
              </el-table-column>
              <el-table-column
                prop="typeId"
                label="编号"
                min-width="250px"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
                min-width="150px"
              ></el-table-column>

              <el-table-column label="操作" min-width="150px">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="EditType(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="DeleteTypeO(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryinfo.pageNum"
            :page-size="queryinfo.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加二级类型对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加二级类别"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域  表单-->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="类型名称：" prop="typeName">
          <el-input
            v-model.trim="addForm.typeName"
            placeholder="请输入类型名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addType">确认</el-button>
      </span>
    </el-dialog>

    <!-- 移除二级类别-->
    <el-dialog
      :close-on-click-modal="false"
      title="移除二级类别名称"
      :visible.sync="deleteDialogVisable"
      width="50%"
    >
      <i class="texts">已存在的二级类别名称列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="ADDRemovedType"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="TypeData0"
          @selection-change="handleRemovedChange"
          max-height="350"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="typeId" label="类别ID"></el-table-column>
          <el-table-column prop="typeName" label="类别名称"></el-table-column>
          <el-table-column label="是否含有子分类">
            <template slot-scope="scope">
              <div v-if="scope.row.children.length > 0">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisable = false">取消</el-button>
        <el-button type="primary" @click="ConfirmremoveType">移除</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">选择要移除的二级类别名称列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="confirmRemovedType"
          max-height="350"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="typeId" label="类别ID"></el-table-column>
          <el-table-column prop="typeName" label="类别名称"></el-table-column>
          <el-table-column label="是否含有子分类">
            <template slot-scope="scope">
              <div v-if="scope.row.children.length > 0">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="NotremoveTypeById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!-- 添加子类别对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加子类别"
      :visible.sync="addDialogVisible1"
      width="30%"
      @close="addDialogClosed1"
    >
      <!-- 内容主体区域  表单-->
      <el-form
        :model="addForm1"
        :rules="addFormRules1"
        ref="addFormRef1"
        label-width="100px"
      >
        <el-form-item label="所属对象：" prop="parentId">
          <ELselect
            v-model="addForm1.parentId"
            :options="ParentIds"
            :optionKey="ParentIdkeys"
            Splaceholder="请选择所属的二级类别"
          ></ELselect>
        </el-form-item>

        <el-form-item label="类型名称：" prop="typeName">
          <el-input
            v-model.trim="addForm1.typeName"
            placeholder="请输入类型名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible1 = false">取消</el-button>
        <el-button type="primary" @click="addType1">确认</el-button>
      </span>
    </el-dialog>

    <!-- 移除三级类别-->
    <el-dialog
      :close-on-click-modal="false"
      :title="title1"
      :visible.sync="deleteDialogVisable1"
      width="50%"
    >
      <i class="texts">已存在的子类别名称列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="ADDRemovedType1"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="TypeData1"
          @selection-change="handleRemovedChange1"
          max-height="350"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="typeId" label="类别ID"></el-table-column>
          <el-table-column prop="typeName" label="类别名称"></el-table-column>
        </el-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisable1 = false">取消</el-button>
        <el-button type="primary" @click="ConfirmremoveType1">移除</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">选择要移除的子类别名称列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="confirmRemovedType1"
          max-height="350"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="typeId" label="类别ID"></el-table-column>
          <el-table-column prop="typeName" label="类别名称"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="NotremoveTypeById1(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!-- 修改的对话框 disabled表示禁用-->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑信息"
      :visible.sync="editDialogVisible"
      width="30%"
      @close="editDialogClosed('editFormRef')"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="所属对象：" prop="parentId">
          <el-input v-model.trim="editForm.parentId" disabled></el-input>
        </el-form-item>
        <el-form-item label="类型名称：" prop="typeName">
          <el-input v-model.trim.trim="editForm.typeName" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="展示顺序：" prop="dispOrder">
          <el-input v-model.trim="editForm.dispOrder" clearable></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editInfo">确定</el-button>
      </span>
    </el-dialog>

    <!-- 修改的对话框 disabled表示禁用-->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑信息"
      :visible.sync="editDialogVisible1"
      width="30%"
      @close="editDialogClosed('editFormRef1')"
    >
      <el-form
        :model="editForm1"
        :rules="editFormRules1"
        ref="editFormRef1"
        label-width="100px"
      >
        <el-form-item label="类型名称：" prop="typeName">
          <el-input v-model.trim="editForm1.typeName" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible1 = false">取消</el-button>
        <el-button type="primary" @click="editInfo1">确定</el-button>
      </span>
    </el-dialog>
    <!-- 修改的对话框 disabled表示禁用-->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑信息"
      :visible.sync="editDialogVisible2"
      width="500px"
      @close="editDialogClosed('editFormRef2')"
    >
      <el-form
        :model="editForm2"
        :rules="editFormRules2"
        ref="editFormRef2"
        label-width="140px"
      >
        <el-form-item label="一级分类名称：" prop="typeName">
          <el-input v-model.trim="editForm2.typeName" placeholder="请输入名称" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible2 = false">取消</el-button>
        <el-button type="primary" @click="editInfo2">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ELselect from '@/components/myself/el_select'
import { getALL, getTypeID, TemplateUpdate, DeleteTemplateTypeSons, TemplateInsert, DeleteTemplateTypes, TemplateInsertSon, getObjectTypeList } from '@/api/cloudmarket/objectType'
import { deleteDialog } from '@/libs/confirmDialog'
import { dynamicColumnTypeId } from '@/components/myself/dynamicCol'
import { GetAllList, getTopAllType } from '@/components/myself/apiList'

export default {
  components: { ELselect },

  data () {
    return {
      firstSelected: undefined,
      // whx

      OneLabelIds: [],
      OneLabelquery: '',
      queryinfoType: '',
      queryinfo: {
        name: '',
        pageNum: 1,
        pageSize: 6
      },
      total: 0,
      TypeList: [],
      TypeData: [],
      defaultProps: {
        children: 'children',
        label: 'typeName'
      },
      ClickTypeId: {},
      Disabled: false,

      //新建按钮
      addDialogVisible: false,
      // 添加用户的表单数据
      addForm: {
        typeName: '',
        itemType: '',
        parentId: ''
      },
      addFormRules: {
        typeName: [
          { required: true, message: '类型名称不能为空', trigger: 'blur' },
        ],
      },

      // 移除
      deleteDialogVisable: false,
      RemoveTypeData: [],
      confirmRemovedType: [],
      multipleRemove: [],
      TypeData0: [],

      // 添加用户的表单数据
      addDialogVisible1: false,
      addForm1: {
        typeName: '',
        itemType: '',
        parentId: ''  // 父typeId
      },
      // 
      addFormRules1: {
        parentId: [
          { required: true, message: '所属类型不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ],
        typeName: [
          { required: true, message: '类型名称不能为空', trigger: 'blur' },
        ],
      },
      // 分类标签
      ParentIds: [],
      ParentIdkeys: {
        value: 'typeId',
        label: 'typeName',
        label2: ''
      },

      // 编辑按钮
      editDialogVisible: false,
      editForm: {
        typeName: '',
        parentId: '',
        dispOrder: '',
      },
      editFormRules: {
        typeName: [
          { required: true, message: '类型名称不能为空', trigger: 'blur' },
        ],
        dispOrder: [
          { required: true, message: '展示顺序不能为空（数字越大，展示的优先级越高）', trigger: 'blur' },
          { min: 1, max: 5, message: '长度需在1-5个字符间', trigger: 'blur' }
        ]
      },

      // 移除1
      deleteDialogVisable1: false,
      RemoveTypeData1: [],
      confirmRemovedType1: [],
      multipleRemove1: [],
      TypeData1: [],
      title1: '',

      // 编辑按钮1
      editDialogVisible1: false,
      editForm1: {
        typeName: '',
      },
      editFormRules1: {
        typeName: [
          { required: true, message: '类型名称不能为空', trigger: 'blur' },
        ]
      },
      // 编辑按钮2
      editDialogVisible2: false,
      editForm2: {
        typeName: '',
      },
      editFormRules2: {
        typeName: [
          { required: true, message: '一级名称不能为空', trigger: 'blur' },
        ]
      },
    }
  },
  watch: {
    queryinfoType (val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted () {
    this.getOneLevelType()
  },
  methods: {
    async getOneLevelType () {
      this.OneLabelIds = await getTopAllType()
      this.OneLabelquery = this.OneLabelIds[0].typeId;
      this.firstSelected = this.OneLabelIds[0];
      this.getList()
    },
    changeType (val) {
      this.OneLabelquery = val
      this.queryinfoType = ''
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.typeName.indexOf(value) !== -1;
    },
    handleNodeClick (data, node) {
      this.getChildrenList(data, node.level)
    },

    // 开始
    async getList () {
      this.TypeData = await GetAllList(this.OneLabelquery, this.OneLabelquery, this.TypeData, this)
      this.getChildrenList(this.TypeData[0], 1)

    },

    async getChildrenList (para, level) {
      this.TypeList = []
      if (para) {
        this.Disabled = false
        if (level === 1) {
          const res1 = await getObjectTypeList(this.queryinfo.pageNum, this.queryinfo.pageSize, { parentId: para.typeId, typeName: this.queryinfo.name, itemType: para.itemType })
          if (res1.status !== 200) return this.$message.error('获取失败')
          this.TypeList = res1.data.data.list
          this.total = res1.data.data.total
        } else {
          this.TypeList.push(para)
          this.total = 1
          this.Disabled = true
        }
        this.ClickTypeId = para
      }
    },

    async Edited1 (para) {
      // 先获取原有数据，再进行修改刷新
      const res = await getTypeID(para.typeId)
      if (res.status !== 200) return this.$message.error('查看原数据失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.editForm1 = res.data.data
      this.editDialogVisible1 = true
    },
    async onClickFirstLevel (target) {
      this.editForm2 = target
      console.log('this.target :>> ', target);
      this.editDialogVisible2 = true
    },
    editInfo1 () {
      this.$refs.editFormRef1.validate(async valid => {
        if (valid) {
          const res = await TemplateUpdate(this.editForm1)
          if (res.status !== 200) return this.$message.error('修改失败')
          this.editDialogVisible1 = false
          this.getList()
          this.$message.success('修改成功')
        }
      })
    },
    editInfo2 () {
      this.$refs.editFormRef2.validate(async valid => {
        if (valid) {
          const res = await TemplateUpdate(this.editForm2)
          if (res.status !== 200) return this.$message.error('修改失败')
          this.editDialogVisible2 = false
          this.getList()
          this.$message.success('修改成功')
        }
      })
    },
    async Delete1 (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await DeleteTemplateTypes([para.typeId])
        if (res.status !== 200) return this.$message.error('删除操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.getList()
      }
    },

    // 
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    addType () {
      this.$refs.addFormRef.validate(async valid => {
        // 可以发起添加的网络请求
        if (valid) {
          this.addForm.itemType = this.OneLabelquery
          this.addForm.parentId = this.OneLabelquery
          const res = await TemplateInsert(this.addForm)
          if (res.status !== 200) return this.$message.error('添加失败')
          if (res.data.status === 2001 || res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('添加成功')
          this.addDialogVisible = false
          this.getList()
        }
      })
    },

    // 移除
    async deleteType () {
      this.confirmRemovedType = []
      this.multipleRemove = []
      this.deleteDialogVisable = true
      this.TypeData0 = await GetAllList(this.OneLabelquery, this.OneLabelquery, this.TypeData, this)
    },
    handleRemovedChange (val) {
      this.multipleRemove = val
    },
    ADDRemovedType () {
      this.confirmRemovedType = dynamicColumnTypeId(this.multipleRemove)
    },
    NotremoveTypeById (rowinfo) {
      this.confirmRemovedType = this.confirmRemovedType.filter((data) => { return data.typeId !== rowinfo.typeId })
      this.multipleRemove = this.confirmRemovedType
    },
    async ConfirmremoveType () {
      if (this.confirmRemovedType.length === 0) return this.$message.error('请选择需要移除的数据')
      const Result = await deleteDialog('确定移除?', this)
      if (Result === 'confirm') {
        let RemoveTypeids = []
        this.confirmRemovedType.forEach(item => {
          RemoveTypeids.push(item.typeId)
        })
        const res = await DeleteTemplateTypes(RemoveTypeids)
        if (res.status !== 200) return this.$message.error('批量移除操作失败')
        this.getList()
        this.deleteDialogVisable = false
      }
    },

    // 子分类
    getSerchList () {
      this.getChildrenList(this.ClickTypeId, 1)
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.queryinfo.pageSize = 6
      this.getSerchList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getSerchList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getSerchList()
    },
    addDialogClosed1 () {
      this.$refs.addFormRef1.resetFields()
    },
    async InsertChildren () {
      this.addDialogVisible1 = true
      const res = await getALL(this.OneLabelquery, this.OneLabelquery)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ParentIds = dynamicColumnTypeId(res.data.data)
    },
    addType1 () {
      this.$refs.addFormRef1.validate(async valid => {
        if (valid) {
          this.addForm1.itemType = this.OneLabelquery
          const res = await TemplateInsertSon(this.addForm1)
          if (res.status !== 200) return this.$message.error('添加失败')
          if (res.data.status === 2001 || res.data.status !== 200) return this.$message.error(res.data.message)

          this.$message.success('添加成功')
          this.addDialogVisible1 = false
          this.getList()
        }
      })
    },
    // 
    async EditType (para) {
      // 先获取原有数据，再进行修改刷新
      const res = await getTypeID(para.typeId)
      if (res.status !== 200) return this.$message.error('查看原数据失败')
      this.editForm = res.data.data
      this.editDialogVisible = true
    },
    editDialogClosed (ref) {
      this.$refs[ref].resetFields()
    },
    editInfo () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const res = await TemplateUpdate(this.editForm)
          if (res.status !== 200) return this.$message.error('修改失败')
          if (res.data.status === 2001 || res.data.status !== 200) return this.$message.error(res.data.message)

          this.editDialogVisible = false
          this.getList()
          this.getSerchList()
          // 提示修改成功
          this.$message.success('修改成功')
        }
      })
    },

    // 移除1
    async deleteType1 () {
      this.title1 = "移除" + this.ClickTypeId.typeName + "的子类别名称"
      this.confirmRemovedType1 = []
      this.multipleRemove1 = []
      this.deleteDialogVisable1 = true
      const res = await getALL(this.ClickTypeId.typeId, this.OneLabelquery)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.TypeData1 = res.data.data
    },
    handleRemovedChange1 (val) {
      this.multipleRemove1 = val
    },
    ADDRemovedType1 () {
      this.confirmRemovedType1 = dynamicColumnTypeId(this.multipleRemove1)
    },
    NotremoveTypeById1 (rowinfo) {
      this.confirmRemovedType1 = this.confirmRemovedType1.filter((data) => { return data.typeId !== rowinfo.typeId })
      this.multipleRemove1 = this.confirmRemovedType1
    },
    async ConfirmremoveType1 () {
      if (this.confirmRemovedType1.length === 0) return this.$message.error('请选择需要移除的数据')
      const Result = await deleteDialog('确定移除?', this)
      if (Result === 'confirm') {
        let RemoveTypeids = []
        this.confirmRemovedType1.forEach(item => {
          RemoveTypeids.push(item.typeId)
        })
        const res = await DeleteTemplateTypeSons(RemoveTypeids)
        if (res.status !== 200) return this.$message.error('批量移除操作失败')
        this.getList()
        this.deleteDialogVisable1 = false
      }
    },
    // 单个删除
    async DeleteTypeO (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await DeleteTemplateTypeSons([para.typeId])
        if (res.status !== 200) return this.$message.error('删除操作失败')
        this.getList()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.text {
  text-align: left;
  margin-left: 30px;
}
.text1 {
  font-size: 18px;
  font-weight: 700;
  width: 100px;
}
.text11 {
  width: 250px;
}
.text12 {
  width: 100px;
  // align-items: ;
}

.link1 {
  font-size: 16px;
  width: 100%;
  margin-right: 10px;
  font-weight: 700;
}
.row2 {
  margin-top: 50px;
}

// /deep/ .el-tree-node__content > .el-tree-node__label {
//   font-size: 18px;
// }
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #a28991;
}
.texts {
  color: blue;
  font-size: 18px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.elTree {
  max-height: 450px;
  overflow: auto;
}
.divSpan {
  font-size: 16px;
}
/deep/ .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  .change-btn {
    color: #409EFF;
    visibility: visible !important;
  }
}
</style>
